@import "../_constants.scss";

.wrap {
  margin-top: 20px;

  .topWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .viewSwitch {
    margin-bottom: 15px;
    margin-top: 20px;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      li {
        cursor: pointer;
        margin-right: 15px;
        border-bottom: 4px solid transparent;

        &.active {
          border-bottom: 4px solid $primary;
        }
      }
    }

    @include screen("isBig") {
      display: none;
    }
  }

  .innerWrap {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .note {
      font-size: $textSize-small;
      text-align: justify;
      padding: 0 15px;
    }

    @include screen("isBig") {
      flex-direction: row;
    }

    .tile {
      width: 100%;

      @include screen("isBig") {
        width: 48%;
      }

      .subtitle {
        display: none;
        margin-bottom: 10px;
        color: $primary;

        @include screen("isBig") {
          display: block;
        }
      }

      &.sent {
        display: none;

        &.switch_sent {
          display: block;
        }

        @include screen("isBig") {
          display: block;
        }
      }

      &.received {
        display: none;

        &.switch_received {
          display: block;
        }

        @include screen("isBig") {
          display: block;
        }
      }
    }
  }

  .more {
    width: 100%;
    margin: 20px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow: wrap;

    button {
      width: 210px;
      margin: 0.7em 1em;
    }
  }
}
