@import "../constants.scss";

.formDarce {
  transition: all 0.3s ease - in -out;
  opacity: 1;

  .loading {
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #fff;
      width: 48px;
      height: 48px;
    }
  }

  .formBlock {
    margin: 2em 1em 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .oou {
    p {
      margin-bottom: 0.1em;
    }

    .oouLinks {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      a {
        margin-right: 1em;
      }
    }
  }

  .actionButtons {
    display: flex;
    justify-content: center;
    row-gap: 1em;
    column-gap: 1em;
    flex-direction: column;

    @include screen("isBig") {
      flex-direction: row;
    }
  }
}
