@import "../constants.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 0px;
  padding-right: 0px;

  @include screen("isBig") {
    padding-left: 120px;
    padding-right: 120px;
  }

  .headerText {
    color: $text;
    font-weight: 600;
    font-size: $textSize-title;
  }
}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  p {
    color: $text;
    font-weight: bold;
    font-size: $textSize-big;
  }
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;

  .imageBlock {
    flex: 1 0 200px;
    max-width: 200px;
    text-align: center;
    cursor: pointer;
    position: relative;

    .image {
      max-width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 8px;
    }

    .imageDescription {
      color: $text;
      text-align: center;
    }

    .imageDescriptionMissing {
      color: $primary;
      text-align: center;
    }

    .imageControlsContainer {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      gap: 10px;
    }

    .imageControl {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: $white;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 4px;
      transition: 0.3s all ease-in-out;
      color: $text;
      background-color: $white;

      // &:hover{
      //     background-color: $primary;
      //     color:$white;
      //     border:1px solid $white;
      // }
    }
  }
}
.whatToUpload {
  margin-top: 10px;
  margin-bottom: 14px;
  font-weight: bold;
}

.fullScreenPhoto {
  min-width: 150px;
  max-height: 50vh;
}

.monthSelect,
.yearSelect {
  width: 20%;
  min-width: 130px;
  margin: 10px 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:focus {
    border: 1px solid $primary;
  }
}

.inputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.description {
  margin-block: 0.5rem;
  padding-left: 0px;
  padding-right: 0px;

  @include screen("isBig") {
    padding-left: 120px;
    padding-right: 120px;
  }
}

// ImageContainerAlt
.imageContainerAlt {
  padding-top: 1.5rem;
  max-width: 920px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 1024px) {
    gap: 1.5rem;
  }
}

.imageContainerAltImageWrapper,
.imageContainerAltDescriptionWrapper,
.imageContainerAltControlsWrapper {
  background-color: #f5f5f4;
}

.imageContainerAltDescriptionWrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 1rem;

  @media (max-width: 1024px) {
    justify-content: center;
  }
}

.imageContainerAltControlsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding: 1rem;
}

.imageContainerAltWrapper {
  display: grid;
  grid-template-columns: 300px auto 70px;
  gap: 0.5rem;
  min-height: 200px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    max-height: none;
  }
}

.imageContainerAltImageWrapper {
  width: 300px;
  min-height: 200px;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: auto;
  }
}

.imageContainerAltImage {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.15s ease-in-out;
  transform: scale(1);

  &:hover {
    transform: scale(1.02);
  }
}

.imageContainerAltControl {
  border: none;
  outline: none;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #e2e2df;
  }
}

.drawerContainer {
  width: 100%;
  min-width: 300px;
  max-width: 650px;
  height: 400px;
  background-color: $white;
  border-radius: 20px;
  box-shadow: $shadowColor;
  display: flex;
  align-items: start;
  padding: 20px;
  gap: 20px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .dndInput {
    width: 50%;
    height: 100%;
    border: 3px dashed $secondary;
    border-radius: 20px;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 768px) {
      width: 80%;
    }

    input[type="file"] {
      display: none;
    }

    .uploadButtons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    .allowedTypes {
      font-size: $textSize-small;
      color: rgb(148, 148, 148);
      margin-top: 20px;
    }
  }
}

.uploadTitle {
  font-weight: 600;
  font-size: $textSize-big;
}

.uploadDescription {
  margin-block: 0.5rem;
}
