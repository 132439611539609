@import "../_constants.scss";

.wrapper {
  p {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  strong {
    color: $primary;
    font-weight: 800;
  }
}

.highlightedText {
  color: $primary;
  font-weight: 800;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  a {
    text-decoration: none;
  }
}

.heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  padding-top: 2rem;
}
