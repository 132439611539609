@import "../_constants.scss";
@import "../../node_modules/bulma/bulma.sass";

.content {
  h1 {
    font-size: $title-size;
    font-weight: $title-weight;
    line-height: $title-line-height;
  }

  p {
    margin: 1em 0;
  }
}
