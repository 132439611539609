@import "../_constants.scss";

.wrap {
  margin-top: 20px;

  .preFormText {
    margin-bottom: 2em;

    p {
      text-align: justify;
    }
  }

  .successBtn {
    margin: 3em 0;
    text-align: center;
  }

  .loading {
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #fff;
      width: 48px;
      height: 48px;
    }
  }

  .linkWrap {
    margin: 2em 0;
    text-align: center;

    .linkBoxWrap {
      margin-bottom: 1em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 1em;

      @include screen("isBig") {
        flex-direction: row;
        column-gap: 1em;
      }
    }
  }
}
