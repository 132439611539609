@import "../constants.scss";

.wrap {
  max-width: 500px;
  text-align: center;
  margin: 20px auto;

  .nav {
    margin-top: 3em;
    text-align: center;
  }
}
