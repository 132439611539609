@import "../_constants.scss";

 


.container{
  width:100%;
  height:100vh;
  min-height:300px;
  max-height:900px;
  margin-top:40px;
  position:relative;

  .iframe{
    width:100%;
    height:100%;
    border:none;
  }
  
  .loading {
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #fff;
      width: 48px;
      height: 48px;
    }
  }
}