@import "../constants";

.loadingBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 50px;
  min-height: 514px;
}

.container {
  margin: 30px 15px;
  font-size: $textSize-medium;
  .Heading {
    font-size: $textSize-title;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .Row {
    width: 100%;
    margin: 30px auto;
    border-top: 0.5px solid #eee;
  }
  .isTooLong {
    display: flex;
    flex-direction: column;
    @include screen("isBig") {
      flex-direction: row;
    }
  }
  .requestTitle {
    white-space: normal;
    @include screen("isBig") {
      white-space: nowrap;
    }
  }
  .requestStatus {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: baseline;
    @include screen("isBig") {
      flex-direction: row;
    }
  }
  .requestDescription {
    display: flex;
    align-items: baseline;
  }

  .userStoryContainer {
    display: block;
    .Tooltip {
      font-size: $textSize-small;
      margin-bottom: 20px;
      font-style: italic;
      width: 100%;
    }

    .LifeStory {
      width: 100%;
    }

    /* Headings */
    .LifeStory h1,
    .LifeStory h2,
    .LifeStory h3,
    .LifeStory h4,
    .LifeStory h5,
    .LifeStory h6 {
      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    /* Paragraphs */
    .LifeStory p {
      margin-bottom: 1em;
    }

    /* Lists */
    .LifeStory ul,
    .LifeStory ol {
      margin: 1em 0;
      padding-left: 20px;
    }

    .LifeStory li {
      margin-bottom: 0.5em;
    }

    /* Tables */
    .LifeStory table {
      margin-bottom: 1em;
    }

    .LifeStory table th,
    .LifeStory table td {
      padding: 10px;
    }

    /* Images */
    .LifeStory img {
      margin: 1em 0;
    }

    /* Blockquotes */
    .LifeStory blockquote {
      margin: 1em 0;
      padding: 10px 20px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    margin: 15px;
    font-size: $textSize-medium;

    .Heading {
      font-size: $textSize-title;
    }

    .MainSection {
      .Row {
        width: 100%;
      }

      .nameHeader {
        flex-direction: column;
        align-items: flex-start;

        .Head,
        .firstMain {
          margin-bottom: 5px;
        }
      }
    }

    .userStoryContainer {
      .headingContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 25px;

        .Heading {
          margin-bottom: 25px;
        }
      }
    }
  }
}
