@import "../constants.scss";

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  .title {
    font-size: 50px;
    display: inline-block;
    animation: type 0.5s alternate infinite;
    text-align: center;

    span {
      display: block;
      background-color: $primary;
      color: #fff;
      font-weight: bold;
      padding: 0.2em 0.6em;

      @include screen("isBig") {
        display: inline;
      }
    }
  }

  .nav {
    margin-top: 3em;
  }
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
