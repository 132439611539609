@import "../constants.scss";

.isPrimary {
  width:fit-content;
}

.contactContainer {
    padding: 10px 10px 10px 0;
  }
  
  .contactHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .contactName {
      font-weight: bold;
      font-size: $textSize-big;
      margin-right: 5px;
    }

    .person {
      display: flex;
      flex-direction: column;
      @include screen('isBig'){
        flex-direction: row;
      }
    }

    .birthDate {
      font-size: $textSize-big;
    }
  
    .contactButtons {
      display:flex;
      flex-direction: column;
      @include screen('isBig'){
        flex-direction: row;
      }

      button {
        margin-left: 8px;
        max-height: 35px;
        margin-bottom:5px;
        @include screen('isBig'){
          margin-bottom:0px;
        }
      }

      button:last-child {
        margin-bottom:0px !important;
      }
    }
  }

  .contactContainer:first-child {
    border-top: 2px solid #eee;;
    // border-radius:10px;
  }

  .contactContainer:last-child {
    border-bottom: none;
  }
  
  .contactInfo {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .iconStyle {
      color: #adadad;
      width: 20px;
      height: 20px;
      font-size: 13px;
      margin-left: 5px;
    }

    .infoLabel {
      font-weight: bold;
    }
  
    p {
      margin: 4px 0;
      font-size: $textSize-medium;
    }
  }