@import "../constants";

.sectionNoPadding{
    padding:0 !important; 
}

.mainSectionContainer{
    display:flex;
    flex-direction:column;
    gap:20px;
    padding-left:10px;
    padding-right:10px;
}

.sectionContainer{
    display:flex;
    flex-direction:column;
    gap:20px;
    padding-left:10px;
    padding-right:10px;
}

.sectionButton{
    width:100%;
    display:flex;
    justify-content: space-between;
    background-color: $primary;
    border-radius:20px;
    padding-left:10px;
    padding-right:10px;
    color: $white;
    align-items: center;
    p{
        font-weight: 600;
        padding-top:10px;
        padding-bottom:10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor:pointer;
    }

}

:global {
    @for $i from 2 through 5 {
      .depth-#{$i} {
        opacity: 1 - ($i * 0.2);
      }
    }
  }