@import "../_constants.scss";

.receivedPaymentsInfo {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  b {
    color: $primary;
  }

  a.button {
    margin-top: 1em;
  }
}

.familyStories {
  .description {
    font-size: 0.95em;
    line-height: 1.3em;
    font-style: italic;
  }

  .familyStoriesWrap {
    margin: 20px 0 10px;

    .familyStoryCta {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      flex-grow: 1;

      .familyStoryCtaButton {
        margin: 0 auto 20px;
      }
    }
  }

  .familyStoriesMore {
    text-align: center;
  }
}

.newsFeed {
  margin: 40px 0 20px 0;

  .title {
    padding-bottom: 10px;
  }

  .newsFeedMore {
    text-align: center;
  }
}
