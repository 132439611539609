@import "../constants.scss";

.rssFeedWrap {
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .feedItemWrap {
    width: 100%;
    margin-bottom: 1.6em;
    @include block-shadow-1-no-hover;

    @include screen("isWide") {
      max-width: 48%;
      height: 150px;
    }

    .feedItem {
      text-decoration: none;
      padding: 0;
      margin-bottom: 1.5em;
      border-radius: 0;

      .feedItemDetail {
        $imageSize: 80px;
        $imageSizeBig: 130px;

        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        .image {
          @include screen("isBig") {
            width: $imageSizeBig;
            height: $imageSizeBig;
            margin: 10px 0.6em;
          }

          img {
            display: block;
            width: auto;
            height: auto;
            max-width: $imageSize;
            max-height: $imageSize;
            margin: auto;

            @include screen("isBig") {
              max-width: $imageSizeBig;
              max-height: $imageSizeBig;
            }
          }
        }
        .textblock {
          .title {
            color: $text;
            font-weight: bold;
            padding: 10px 10px 0;
            text-align: left;
            @include fixedLinesCount(1.1em, 1.3em, 3);

            @include screen("isBig") {
              text-align: justify;
              font-size: 1.1em;
            }

            @include screen("isMobile") {
              min-height: 4.5em;
              height: auto;
            }

            font-size: 0.95em;
          }

          .perex {
            display: none;
            color: $text;
            font-size: 0.95em;
            padding: 0 20px 0 10px;
            margin-bottom: 10px;
            text-align: justify;
            @include multiLineEllipsis(1.3em, 3, "...");

            @include screen("isBig") {
              display: inherit;
            }
          }
        }

        .cta {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: auto;
          height: 100%;
          color: $primary;
          fill: $primary;
          text-align: center;
          padding: 15px 10px 15px 20px;
          font-size: 1em;

          .ctaIcon {
            font-weight: 100;
          }
        }
      }

      &:hover {
        text-decoration: none;

        .cta .ctaIcon {
          font-weight: bold;
        }

        .textblock {
          .title,
          .perex {
            color: $primary;
          }
        }
      }
    }
  }
}
