$primary: #e11e0f;
$secondary: #ffd700;
$da-brown: #1e0a00;
$background-light: #fff;
$background-dark: #e5ddbf;
$shadow-color: #ebebeb;
$hover-color: rgba($secondary, 0.5);

$button-background-color: $primary;
$button-border-color: transparent;
$button-hover-border-color: transparent;
$button-color: #fff;

$link: $primary;
$shadowColor: $da-brown;
$text: $da-brown;
$text-strong: $da-brown;
$text-light: $da-brown;

$textSize-huge: 2.2em;
$textSize-title: 1.8em;
$textSize-small-title: 1.5em;
$textSize-big: 1.3em;
$textSize-medium: 1.05em;
$textSize-small: 0.8em;

@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/elements/box.sass";

@mixin block-shadow-1 {
  box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1),
    0 0px 0 1px rgba($scheme-invert, 0.02);

  &:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0 0 2px $link;
  }
}

@mixin block-shadow-1-no-hover {
  box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1),
    0 0px 0 1px rgba($scheme-invert, 0.02);
}

@mixin custom-scroll-bar($size: 12px, $color: #d62929, $bg: #f5f5f5) {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: $size;
    background-color: $bg;
  }

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bg;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $size;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $color;
  }
}

@mixin single-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@mixin tooltip(
  $backgroundColor: rgba(51, 51, 51, 0.9),
  $fontSize: 0.8rem,
  $speed: 0.35s
) {
  $data-tooltip-border: (
    top: t,
    bottom: b,
    left: l,
    right: r,
  );

  $triangle: 0.3rem;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  position: relative;

  &::after {
    background-color: $backgroundColor;
    border-radius: $triangle;
    color: #fff;
    content: attr(data-tooltip);
    font-size: $fontSize;
    font-weight: normal;
    line-height: 1.15rem;
    opacity: 0;
    padding: 0.25rem 0.5rem;
    position: absolute;
    text-align: center;
    text-transform: none;
    transition: opacity $speed;
    visibility: hidden;
    width: 200px;
    white-space: pre-line;
    z-index: 1;
  }

  @each $direction, $d in $data-tooltip-border {
    &.#{$direction} {
      // arrows
      &::before {
        border-style: solid;
        border-width: $triangle;
        content: "";
        opacity: 0;
        position: absolute;
        transition: opacity $speed;
        visibility: hidden;
        @if $direction == top {
          border-color: $backgroundColor transparent transparent transparent;
          top: 0;
          left: 50%;
          margin-left: -$triangle;
        } @else if $direction == bottom {
          border-color: transparent transparent $backgroundColor transparent;
          bottom: 0;
          left: 50%;
          margin-left: -$triangle;
        } @else if $direction == left {
          border-color: transparent transparent transparent $backgroundColor;
          top: $triangle;
          right: calc(110% - #{$triangle});
          margin-top: -$triangle;
        } @else if $direction == right {
          border-color: transparent $backgroundColor transparent transparent;
          top: 50%;
          top: $triangle;
          left: calc(110% - #{$triangle});
          margin-top: -$triangle;
        }
      }

      &::after {
        @if $direction == top {
          bottom: 100%;
          left: 50%;
          transform: translate(-50%);
        } @else if $direction == bottom {
          top: 100%;
          left: 50%;
          transform: translate(-50%);
        } @else if $direction == left {
          top: -$triangle;
          right: calc(110% + #{$triangle});
        } @else if $direction == right {
          top: -$triangle;
          left: calc(110% + #{$triangle});
        }
      }
    }
  }

  &:hover::after,
  &:hover::before {
    visibility: visible;
    opacity: 1;
  }
}

@mixin fixedLinesCount($fontSize: 1.1em, $lineHeight: 1.3em, $lineCount: 1) {
  // Only em / rem sizes !
  font-size: $fontSize;
  line-height: $lineHeight;
  height: $lineCount * ($fontSize + ($lineHeight - 1em));
  overflow: hidden;
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $moreLabel: "...") {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: $moreLabel;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
  }
}
// @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $moreLabel: '...');

/*
    @mixin screen($size)
    
    usage:
    @include screen('tablet') {
      width: 90%;
    }
  */

@mixin screen($size) {
  $wide: "1000px";
  $big: "760px";
  $mobile: "420px";

  @if $size == "isWide" {
    @media only screen and (min-width: $wide) {
      @content;
    }
  }

  @if $size == "isBig" {
    @media only screen and (min-width: $big) {
      @content;
    }
  }

  @if $size == "isMobile" {
    @media only screen and (max-width: $mobile) {
      @content;
    }
  }
}
