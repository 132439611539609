@import "../_constants.scss";

.wrap {
  margin: 30px 15px;
  text-align: left;
  min-height: 60vh;

  .topWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.divWrap {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: fit-content;

  div {
    margin-top: 20px;
  }

  h2 {
    color: red;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.buttonWrapper {
  margin-top: 1.5rem;
  width: fit-content;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}
