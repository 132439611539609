@import "../constants.scss";

.container{
    width:100%;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    display:flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    background-color: rgba(0,0,0,0.5);

    .window{
        width:90%;
        max-width:500px;
        padding:20px;
        background-color: #fff;
        border-radius:10px;
        display:flex;
        flex-direction: column;

        .title{
            font-size: $textSize-big;
            font-weight: 600;
            text-align:left;
        }

        .description{
            font-size: $textSize-medium;
            font-weight: 400;
            text-align:left;
        }   

        .buttons{
            margin-top:30px;
            display:flex;
            gap:20px;
            justify-content: flex-end;
            align-items: center;
        }
    }
}