@import "../_constants.scss";

.wrap {
  margin-top: 20px;

  .description {
    font-size: 0.95em;
    line-height: 1.3em;
    font-style: italic;
  }

  .spacer {
    height: 2em;
  }

  .familyStoriesActions {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    button {
      max-width: 300px;
    }
  }
}

.familyStoryCtaButton {
  text-align: center;
  margin: 0 auto 20px;

  @include screen("isBig") {
  }
}
