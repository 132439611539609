@font-face {
    font-family: 'Overpass';
    src: url('subset-Overpass-Italic.eot');
    src: url('subset-Overpass-Italic.eot?#iefix') format('embedded-opentype'),
        url('subset-Overpass-Italic.woff2') format('woff2'),
        url('subset-Overpass-Italic.woff') format('woff'),
        url('subset-Overpass-Italic.ttf') format('truetype'),
        url('subset-Overpass-Italic.svg#Overpass-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass';
    src: url('subset-Overpass-Bold.eot');
    src: url('subset-Overpass-Bold.eot?#iefix') format('embedded-opentype'),
        url('subset-Overpass-Bold.woff2') format('woff2'),
        url('subset-Overpass-Bold.woff') format('woff'),
        url('subset-Overpass-Bold.ttf') format('truetype'),
        url('subset-Overpass-Bold.svg#Overpass-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass';
    src: url('subset-Overpass-Regular.eot');
    src: url('subset-Overpass-Regular.eot?#iefix') format('embedded-opentype'),
        url('subset-Overpass-Regular.woff2') format('woff2'),
        url('subset-Overpass-Regular.woff') format('woff'),
        url('subset-Overpass-Regular.ttf') format('truetype'),
        url('subset-Overpass-Regular.svg#Overpass-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

