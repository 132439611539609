@import "../_constants.scss";

    .nameHeader {
      display: flex; 
      align-items: center;
      font-size: $textSize-medium;
      

      .Head {
        font-weight: bold;
        margin-right: 10px;

        @include screen('isBig'){
          display: inline-block;
          white-space: nowrap;
        }
      }
    }
  

    @media (max-width: 768px) {
      
        .MainSection {
    
          .nameHeader {
            flex-direction: column;
            align-items: flex-start;
    
            .Head, .firstMain {
              margin-bottom: 5px;
            }
          }
        }
    
       
      }
