@import "../_constants.scss";

.alertContainer{
    width:100%;
    padding-top:8px;
    padding-bottom:8px;
    padding-right: 16px;
    padding-left:16px;
    border-radius:8px;

    .title{
        font-size: $textSize-medium;
        font-weight: 600;
    }
    .description{
        font-size: $textSize-small;
        font-weight: 400;
    }
}

.alertContainerError{
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
    border-left:3px solid #721c24;
}

.alertContainerSuccess{
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
    border-left: 3px solid #155724;
}

.alertContainerWarning{
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
    border-left:3px solid #856404;
}

.alertContainerInfo{
    background-color: #d1ecf1;
    border-color: #bee5eb;
    color: #0c5460;
    border-left:3px solid #0c5460;
}