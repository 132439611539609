.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .footerMenu { 
        display:flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 20px;
        margin-top: 20px;
        align-items: center;
    }
}