@import "../_constants.scss";

.languageItem {
  cursor: pointer;
  padding: 1em 1em;
  display: inline-flex;

  @include screen("isBig") {
    padding: 10px 0.3em 5px;
    border-bottom: 5px solid $secondary;
  }

  &:hover,
  &:focus,
  &.is-active {
    @include screen("isBig") {
      border-bottom: 5px solid $primary;
    }
  }
}
