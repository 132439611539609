@import "../_constants.scss";

.wrap {
  margin-top: 20px;
  text-align: left;

  .loading {
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #fff;
      width: 48px;
      height: 48px;
    }
  }


  .headingSection {
    display: flex;             
    justify-content: space-between; 

    .title {
      font-size: $textSize-title;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .MainSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    font-size: $textSize-medium;
  }

  .housingTypeStyle {
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  .contactHeading {
    font-size: $textSize-title;
    font-weight: bold;
  }

  .smallTitle {
    font-size: $textSize-small-title;
    font-weight: bold;
  }

  .informativeText {
    font-size: $textSize-small;
    width: 100%;
    margin: 20px 0 30px 0;
    font-style: italic;
  }

  .isTooLong {
    display: flex;
    flex-direction: column;
    @include screen('isBig'){
      flex-direction: row;
    }
  }

  .informativeTextMain {
    font-size: $textSize-small;
    margin-top: 5px;
    width: 100%;
    font-style: italic;
  }

  .person {
    margin: 0 auto;
    text-align: left;
    width: 100%;
  }

  .formStyle {
    border-top: 2px solid #eee;
    padding-top: 10px;
  }
  
  .formStyleExpanded {
    // padding: 10px 4px;
    // border-radius:10px;
    // background-color: #dedede;;
  }

  .noteBlock {
    background: $secondary;
    margin-bottom: 2em;
    padding: 0.7em 1em;
  }
}

.collapsed{
  width:100%;
  display:flex;
  justify-content: space-between;
  align-items:center;

  .name{
    font-size: $textSize-medium;
    font-weight: 600;
    text-align:left;
  }
}



.expandedPrimaryCheckbox{
  display:flex;

  .iconStyle {
    color: #adadad;
    width: 20px;
    height: 20px;
    font-size: 13px;
    margin-left: 5px;
  }
}
.deleteButtonContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  .deleteButton{
    height: 35px;
  }
  
  .saveButton{
    height: 35px;
  }
}

.unsavedChangesContainer{
  display:flex;
  justify-content: flex-end;
  align-items:center;
  position:absolute;
  margin-top:-10px;
  background-color: $primary;
  padding-left:15px;
  padding-right:15px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color:$white;
  gap:10px;
}

.personLocked{
  background-color: red !important;
}
