@import "../constants";

.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
}

.modalContainer{
    display:flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
    border-left:3px solid $primary;

    .modalHeader{
        width:100%;

        .title{
            font-size: $textSize-medium;
            font-weight: 600;
        }
    
        .description{
            font-size: $textSize-small;
            text-align: left;
        }
    }

    .footerMenu{
        display:flex;
        justify-content: center;
        align-items: center;
        gap:20px;
    }

    .changesContainer{
        margin-top:20px;
        margin-bottom:20px;
        width:100%;

        .changeContainer{
            margin-top:30px;


            .changeHeader{
                display:flex;
                justify-content: space-between;
                align-items: center;

                .label{
                    font-size: $textSize-medium;
                    font-weight: 600;
                    color: $primary;
                }

                .revertChange{
                    color: $da-brown;
                    font-weight: bold;
                    cursor: pointer;
                }
            }

            .change{
                margin-top:4px;
                display:flex;
                align-items: center;
                justify-content: center;
                gap:20px;
            }
        }
    }
}

