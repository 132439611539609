@import "../_constants.scss";

$layout_pageMargin: 25px;
$layout_headerSideBoxWidth: 150px;
$layout_navigationHeight: 45px;

.section-page-top-header {
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background-color: $secondary;

  @include screen("isBig") {
    padding: 8px $layout_pageMargin 0;
  }

  .header-action {
    width: $layout_headerSideBoxWidth;

    &.left {
      text-align: left;
      padding: 7px 0 0 7px;

      @include screen("isBig") {
        padding: 7px 0 0 0;
        display: flex;
        flex-flow: column;
        align-items: center;
      }
    }

    &.center {
      padding: 0;

      .hamburger-react {
        margin: 0 auto;
      }

      @include screen("isBig") {
        display: none;
      }
    }

    &.right {
      text-align: right;
      padding: 7px 7px 0 0;

      @include screen("isBig") {
        padding: 7px 0 0 0;
        display: flex;
        flex-flow: column;
        align-items: center;
      }
    }
  }
}

.section-main-page-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  // min-height:calc(61vh - 8px - 30px - 35px);
  min-height:calc(100vh - 311px);

  @include screen("isBig") {
    flex-flow: row;
  }

  .section-page-side-spacer {
    @include screen("isBig") {
      width: $layout_pageMargin;
      height: $layout_navigationHeight;
      background-color: $secondary;
    }
  }

  .section-page-header {
    display: none;
    background-color: $secondary;
    width: $layout_headerSideBoxWidth;
    max-width: $layout_headerSideBoxWidth;
    height: $layout_headerSideBoxWidth;
    padding-top: 15px;

    @include screen("isBig") {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;

      > a {
        margin: auto 0;
      }
    }

    .header-overflow-box-img {
      max-width: $layout_headerSideBoxWidth * 0.9;
      max-height: ($layout_headerSideBoxWidth - 45);
      margin: 0 auto;

      &.logo {
        max-height: ($layout_headerSideBoxWidth - 30);
      }

      &.avatar {
        max-height: ($layout_headerSideBoxWidth - 50);
      }
    }

    .profile-notes {
      font-size: 12px;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .section-page-center {
    width: 100vw;
    min-height: 300px;

    @include screen("isBig") {
      max-width: calc(
        100% - (2 * #{$layout_headerSideBoxWidth}) - (2 * #{$layout_pageMargin})
      );
    }

    .top-header-navigation {
      background: $secondary;
      align-items: center;
      width: 100%;

      @include screen("isBig") {
        display: flex;
        flex-direction: row;
        justify-items: center;
        justify-content: center;
        height: $layout_navigationHeight;
      }

      &.closed {
        visibility: hidden;
        height: 0;

        @include screen("isBig") {
          visibility: visible;
          height: $layout_navigationHeight;
        }
      }

      .navbar-item {
        color: $da-brown;
        text-decoration: none;
        text-align: center;

        @include screen("isBig") {
          border-bottom: 5px solid $secondary;
        }

        &.is-hidden-on-mobile {
          display: none;

          @include screen("isBig") {
            display: inherit;
          }
        }

        &.is-hidden-on-desktop {
          @include screen("isBig") {
            display: none;
          }
        }

        svg {
          height: 1.5em;
        }

        img {
          max-height: 3.5rem;
        }

        &:hover,
        &:focus,
        &.is-active {
          background-color: $secondary;
          color: $primary;
          @include screen("isBig") {
            border-bottom: 5px solid $primary;
          }
        }

        &.navbar-language {
          display: flex;
          flex-direction: row;
          justify-items: center;
          justify-content: center;
          border-bottom: none;

          &:hover,
          &:focus,
          &.is-active {
            border-bottom: none;

            @include screen("isBig") {
              border-bottom: none;
              padding-bottom: 0;
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

.page-main-content-wrap {
  padding: 0 25px;
}

footer {
  background: $secondary;
  min-height: 100px;
  margin-top: 30px;

  .footer-contact {
    padding: 10px 0;
    background: $shadow-color;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .block {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .icon-block {
        width: 36px;
        display: block;
        margin: 2px 15px 0;
        text-align: center;
      }

      .text-block {
        width: 35vw;
        flex-shrink: 1;
        text-align: center;

        @include screen("isBig") {
          &.left {
            text-align: right;
          }

          &.right {
            text-align: left;
          }
        }
      }

      &.phones {
        .text-block {
          &.left {
            text-align: right;
          }

          &.right {
            text-align: left;
          }
        }
      }

      &.emails {
        flex-direction: column;
        text-align: center;

        @include screen("isBig") {
          flex-direction: row;
        }

        .text-block {
          width: 100vw;

          @include screen("isBig") {
            width: 35vw;
            flex-shrink: 1;
          }
        }

        .icon-block {
          display: none;

          @include screen("isBig") {
            display: block;
          }
        }
      }
    }
  }

  .footer-content {
    padding: 20px 20px;

    @include screen("isBig") {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .text-block {
      text-align: center;
      margin-bottom: 20px;

      @include screen("isBig") {
        text-align: left;
        margin-bottom: 0;
      }
    }

    .social-block {
      .social {
        text-align: center;
        margin: 0;
        padding: 0;
        height: 60px;
        overflow: hidden;

        li {
          display: inline-block;
          cursor: pointer;
          width: 60px;
          height: 60px;
          padding-top: 8px;
          font-size: 36px;
          color: #fff;
          margin: 0 6px;
          overflow: hidden;

          &.facebook {
            background-color: #4267b2;
          }

          &.instagram {
            background-color: #e50772;
          }

          &.twitter {
            background-color: #1da1f2;
          }

          &.youtube {
            background-color: #f00;
          }

          &.linkedin {
            background-color: #0077b5;
          }
        }
      }

      @include screen("isBig") {
        margin-top: 20px;
      }
    }
  }
}

.footer-appver {
  background: $secondary;
  text-align: right;
  font-size: 0.5em;
  padding-right: 2em;
}
