@import "../_constants.scss";

.wrap {
  padding: 15px 25px;
  @include block-shadow-1-no-hover;

  .title {
    text-align: center;
  }

  .header {
    text-align: center;
  }

  .ratingWrap {
    margin: 2em 0;

    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    column-gap: 1em;

    @include screen("isBig") {
      flex-direction: row;
    }

    .ratingPre,
    .ratingPost {
      font-size: 0.8em;
      font-weight: bold;
      display: block;
    }

    @include screen("isMobile") {
      .ratingPre {
        align-self: flex-start;
      }

      .ratingPost {
        align-self: flex-end;
      }
    }

    .ratingPre {
      text-align: right;
    }

    .ratingPost {
      text-align: left;
    }

    .rating {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      i {
        font-size: 1em;
        color: #ebebeb;
        padding: 0 7px;
        cursor: pointer;

        @include screen("isBig") {
          font-size: 1.7em;
          padding: 0 10px 0 0;
        }

        &.active {
          color: $primary;
        }
        &.hover {
          color: $primary;
          opacity: 0.5;
        }
      }
    }
  }

  .submit {
    text-align: center;
  }
}

.footnote {
  text-align: center;
  font-size: 0.7em;
  margin-top: 2em;
}

.thankyou {
  text-align: center;
  margin-top: 2em;
}

.feedbackWrap {
  max-width: 750px;
  margin: 2em auto;
}

.spacer {
  margin: 1em 0;
  content: "";
}
