.container{
    display: flex;
    flex-direction: column;
    gap:5px;
    .itemContainer{
        display:flex;
        gap:10px;
    }
    margin-bottom:20px;
}
