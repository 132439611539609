@import "../constants.scss";

.header{
    display:flex;
    width:100%;
    justify-content: space-between;
    align-items:center;
    margin-top: 20px;
    margin-bottom: 20px;

    @include screen('isBig'){
        width: 50%;
        justify-content: space-between;
    }

    .headerTitle{
        font-size: $textSize-title;
        font-weight:700;
    }
}


.infoSection {
    width: 100%;
    min-height: 300px;

    @include screen('isBig'){
        width: 50%;
    }
}

.fileContainer { 
    display:flex;
    justify-content: space-between;
    margin-top:10px;
    margin-bottom:10px;
    font-weight: bold;
    flex-direction: column;;
    @media (min-width: 1600px) {
        flex-direction: row;
    }
}
