@import "../constants.scss";


.inlineFields {
  display: -ms-grid;
  display: grid;
  margin-bottom: 1em;
  gap: 0 1em;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-columns: 1fr 1fr 1fr;

  &.cols-1 {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
  }
  &.cols-2 {
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
  }
  &.cols-3 {
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr;
  }
  &.cols-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
  }

  @include screen("isMobile") {
    display: block;
    width: 100%;

    .select {
      width: 100%;
    }
  }

  .description-right{
    width:fit-content;
  }

  .field {
    &.fieldNoMargin {
      margin: 0;
    }

    .label {
      width: auto;
    }

    .help {
      font-style: italic;
      margin-bottom: 1em;
      text-align: justify;
    }

    .select {
      width: 100%;
    }

    .checkbox {
      width: 100%;
    }

    .input {
      width: 100%;
    }

    .icon {
      svg {
        fill: rgba($text, 0.3);
      }

      &.is-danger {
        svg {
          fill: $primary;
        }
      }
    }
  }
}

.disabled{
  cursor: not-allowed;
  opacity: 0.4;
}

.checkbox {
  
  accent-color: $primary;
  border-radius:8px;

  // Style for when the checkbox is checked
  // &:checked + label::before {
  //   background-color: orange; // Orange background
  //   content: "\2713"; // Unicode checkmark
  //   color: white; // White checkmark
  // }
  //   // Custom checkbox appearance
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 20px;
  //     height: 20px;
  //     border: 2px solid grey; // Border for the checkbox
  //     background-color: white; // Background color
  //     border-radius: 3px; // Optional: for rounded corners
  //   }
}

select:disabled{
  opacity: 0.4;
}

.radioLabel {
  margin-right: 1.5em;

  .radioInput {
    margin-right: 0.3em;
  }
}


.toggle {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;

  @include screen("isBig") {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .radio {
    margin-left: 1.3em;

    &:first-child {
      @include screen("isBig") {
        margin-left: 0;
      }
    }

    input {
      margin-right: 0.5em;
    }
  }
}


.autocompleteOptions{
  position: absolute;
  z-index: 10;
  width: 250px;
  max-height: 200px;
  overflow-y: auto;
  background-color: $white;
  border: 1px solid $grey-lighter;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba($black, 0.1);
  margin-top: 0.5em;
  padding: 0.5em 0;
  font-size: 0.9em;
  color: $text;
  text-align: left;
  list-style: none;
  padding:4px;


  .optionContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left:5px;
    padding-top:3px;
    padding-bottom:3px;
    border-radius:5px;
    cursor:pointer;
    &:hover{
      background-color:$shadow-color;
    }
    .optionValue{
      margin-right: 1em;
      color:$text;
    }
  }
}

.inputTooltip{
  color: $text;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}