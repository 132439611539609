@import "../_constants.scss";

.wrap {
  max-width: 250px;
  margin: 0 auto;

  @include screen("isBig") {
    max-width: 500px;
  }

  .paymentTypeSelect {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .fieldsBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include screen("isBig") {
      flex-direction: row;
    }

    .field {
      @include screen("isBig") {
        width: 240px;
      }
    }
  }

  .submit {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
