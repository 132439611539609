@import "../_constants.scss";

.wrap {
  margin-top: 20px;

  .platbaWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-wrap: nowrap;

    @include screen("isBig") {
      flex-direction: row;
    }

    .item {
      width: 100%;
      margin-top: 2em;

      @include screen("isBig") {
        padding: 1em 1em;
        margin-top: 0;
      }

      .subtitle {
        color: $primary;
      }
    }

    .buttons {
      text-align: center;

      button {
        margin-inline: 10px;
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.qrcode {
  text-align: center;

  img.qrcode {
    max-width: 300px;
  }
}

.footnote {
  font-size: 0.8em;
  text-align: justify;

  p {
    &:first-child {
      &::before {
        font-family: "Font Awesome 6 Pro";
        font-weight: 100;
        font-style: normal;
        margin-right: 5px;
        content: "\f05a";
      }
    }
  }
}
