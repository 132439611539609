@import "../_constants.scss";


.wrap {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow:none;


  @include screen("isBig") {
    flex-direction: row;

    .hamburgerToggle {
      display: none;
    }
  }

  .hamburgerToggle {
    display: flex;
    position: fixed;
    top:30px;
    z-index: 40;
    border-radius: 15px;
    padding:2px;
    right:10px;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    margin: 1em 0;
    background-color: white;
    box-shadow: $shadow;
    width:fit-content;

    @include screen("isBig") {
      display: none;
    }
  }

  .isOpen {
    display: block !important;
  }

  .customSide{
    width:100%;
    text-align: justify;
    height: auto;
    display: none;
    margin-bottom: 1em;

    position:fixed;
    top:110px;
    width:90%;
    left:5%;
    right:5%;
    height:fit-content;
    z-index: 30;
    background-color: white;
    padding:20px !important;



    @include screen("isBig") {
      width: 300px;
      max-width: 300px;
      min-width: 300px;
      display: block;
      padding: 1em 2em;
      position: sticky;
      top:30px;
      height: min-content;
      overflow:hidden !important;
    }

    button {
      margin-bottom: 0.7em;
      width:100%;
      white-space: nowrap;
      overflow: hidden; 
    }
  }

    .side {
      padding: 1em 0;
      text-align: justify;

      //@include screen("isMobile") { display: none;}

      @include screen("isBig") {
        width: 300px;
        max-width: 300px;
        min-width: 300px;
        padding: 1em 2em;
      }

      a {
        margin-bottom: 0.7em;
        white-space: normal;
        height: auto;
      }
    }

    .content {
      width: auto;
      position: relative;
      flex-grow: 1;
    }
  }
