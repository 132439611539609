@import "../_constants.scss";

body {
  height: 100vh;
  width: 100%;
}

.full-date{
  display:none;
  
  @include screen("isBig"){
    display:flex;
    flex-direction: column;
  }
}

.mobile-date{
  display:flex;
  flex-direction:column;
  @include screen("isBig"){
    display:none;
  }

} 

.mobile-date-container{
  display:flex;
  flex-direction:row;
  gap:5px;
  justify-content: center;
}


.loginCard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  text-align: center;

  .logo {
    max-width: 150px;
    max-height: 130px;
    margin-bottom: 20px;
  }

  .loginCardInner {
    background-color: $secondary;
    padding: 1.5em 1em 1em 1em;

    @include block-shadow-1;

    &:hover {
      box-shadow: $box-shadow;
    }

    .title {
      margin-bottom: 1.4em;
      font-size: $textSize-big;
    }

    .field {
      .label {
        text-align: left;
        font-weight: normal;
      }
    }

    .loginButton {
      margin-top: 1.5em;
      font-size: 1em;
    }

    .control {
      .icon {
        // color: $text-light;
      }
    }

    .loginLinks {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }

    .loginLang {
      .navbar-item {
        justify-content: center;
        padding: 0;

        .languageItem {
          border-bottom: 1px solid $secondary;

          &:hover {
            border-bottom: 1px solid $primary;
          }
        }
      }
    }
  }
}

.timeLeft{
  display:flex;
  gap:5px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-top:9px;
  margin-bottom:3px;
  color: $text;
  p{
    font-weight: normal;
  }
}
