@import "./_constants.scss";
@import "../node_modules/bulma/bulma.sass";
//@import url("https://fonts.googleapis.com/css?family=Overpass:300,400,700&display=swap&subset=latin-ext");
@import "./assets/fonts/overpass.css";

@import "./assets/font-awesome/css/fontawesome.css";
@import "./assets/font-awesome/css/brands.css";
@import "./assets/font-awesome/css/thin.css";
@import "./assets/font-awesome/css/solid.css";
@import "./assets/font-awesome/css/regular.css";
// @import "./assets/font-awesome/css/duotone.css";

@import "react-phone-number-input/style.css";


html body {
  font-family: "Overpass", Arial, sans-serif;
  font-size: 1em;
  height:100%;

  //background: $background-light url("./assets/images/bg_mainpage.png");
  background: $background-light;
  color: $da-brown;
}

.btn-secondary{
  background-color: $secondary !important;
  color: $da-brown !important;
  
  &:hover{
    background-color: $da-brown !important;
    color: $secondary !important;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Overpass", Arial;
}

body a {
  text-decoration: none;
  color: $primary;

  &:hover {
    text-decoration: underline;
    color: $primary;

    &.button {
      text-decoration: none;

      
    }

    &.clean-link {
      text-decoration: none;
    }
  }
}

.note {
  font-size: 0.9em;
  font-style: italic;
}

.navbar-item .fa-regular {
  padding-bottom: 5px;
  padding-top: 3px;
}

svg {
  width: 1em;
  height: auto;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.hidden {
  display: none;
}

.font-bold{
  font-weight: bold;
}

.text-big{
  font-size: $textSize-big;
}

.text-medium{
  font-size: $textSize-medium;
}

.swal2-container {
  .swal2-content {
    color: $text;
    text-align: justify;
    font-family: "Overpass", Arial, sans-serif;
  }

  .swal2-actions .swal2-styled {
    border-radius: $radius-rounded;

    &.swal2-confirm {
      background-color: $button-background-color;
      border-color: $button-border-color;
      border-width: $button-border-width;
      color: $button-color;
      border: 2px solid transparent;

      &:hover,
      &:active {
        background-color: transparent;
        background-image: none;
        border-color: $button-background-color;
        color: $button-background-color;
        border: 2px solid $button-background-color;
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.button {
  text-decoration: none;
  border: 2px solid transparent;


  &.is-block {
    width: 100%;
  }

  .fa,
  .fa-regular,
  .fa-thin,
  .fa-solid {
    margin: 0 0.5em 0.3em 0;
  }

  .single-icon {
    margin: 0 0 0.3em 0;
  }

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    background-color: $background-light !important;
    color: $primary !important;
    border: 2px solid $primary !important;
    box-shadow: none;

    .fa,
    .fa-regular,
    .fa-thin,
    .fa-solid {
      color: $primary;
    }

    &:not(:active) {
      box-shadow: none;
    }
  }

  &:disabled {
    background-color: #c9c9c9;
    color: #000;
    border: 2px solid #9e9e9e;

    svg {
      fill: $primary !important;
    }
  }

  &.is-small {
    font-weight: bold;
    font-size: 0.7rem;
    //padding-top: 0.9em;

    svg {
      margin-top: -0.5em;
    }
  }
}

.outline-button {
  text-decoration: none;
  border: 2px solid $primary !important;
  background-color:transparent !important;
  color:$primary !important;

  &.is-block {
    width: 100%;
  }

  .fa,
  .fa-regular,
  .fa-thin,
  .fa-solid {
    margin: 0 0.5em 0.3em 0;
  }

  .single-icon {
    margin: 0 0 0.3em 0;
  }

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    background-color: $primary !important;
    color: $white !important;
    border: 2px solid $primary !important;
    box-shadow: none;

    .fa,
    .fa-regular,
    .fa-thin,
    .fa-solid {
      color: $primary;
    }

    &:not(:active) {
      box-shadow: none;
    }
  }

  &:disabled {
    background-color: #c9c9c9;
    color: #000;
    border: 2px solid #9e9e9e;

    svg {
      fill: $primary !important;
    }
  }

  &.is-small {
    font-weight: bold;
    font-size: 0.7rem;
    //padding-top: 0.9em;

    svg {
      margin-top: -0.5em;
    }
  }
}

.input {
  &:focus {
    border-color: $primary;
  }

  &.PhoneInput {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-right: 0;

    .PhoneInputInput {
      box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
      background-color: hsl(0deg, 0%, 100%);
      border-color: hsl(0deg, 0%, 86%);
      border-radius: 4px;
      color: #1e0a00;
      border: 1px solid transparent;
      border-radius: 4px;
      font-size: 1rem;
      height: 2.5em;
      padding-left: 1em;
    }
  }

  &.PhoneInput--focus {
    .PhoneInputInput {
      border-color: $primary;
    }
  }
}

.checkbox {
  input {
    margin-right: 0.4em;
  }
}

.title {
  margin-bottom: 10px !important;
  font-size: $textSize-title;
}

.tooltip {
  @include tooltip();
}

.notification {
  padding: 0.5em;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-column-gap: 5px;

  &:first-child {
    margin-top: 1em;
  }

  i {
    grid-area: 1 / 1 / 2 / 2;
    padding: 0 0.5em;
    margin: auto 0;
  }
  span {
    grid-area: 1 / 2 / 2 / 3;
    margin: auto 0;
  }
  a {
    grid-area: 1 / 3 / 2 / 4;
    padding: 0 0.5em;
    text-decoration: none;
  }
}

.text-primary {
  color: $primary;
}

.text-secondary{
  color: $secondary;
}

.cursor-pointer{
  cursor: pointer;
}

.flex-center{
  display:flex;
  justify-content: center;
  align-items: center;
}


// GRID SYSTEM 
$grid-columns: 12;
$gutter-width: 10px;

@mixin grid-item($columns) {
    width: 100% / $grid-columns * $columns;
    float: left;
    padding: $gutter-width;
    box-sizing: border-box;
}

.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 $gutter-width;
    box-sizing: border-box;
}

@for $i from 1 through $grid-columns {
    .col-#{$i} {
        @include grid-item($i);
    }
}

@media (max-width: 1200px) {
    @for $i from 1 through $grid-columns {
        .col-md-#{$i} {
            @include grid-item($i);
        }
    }
}

@media (max-width: 768px) {
    @for $i from 1 through $grid-columns {
        .col-sm-#{$i} {
            @include grid-item($i);
        }
    }
}

@media (max-width: 576px) {
    @for $i from 1 through $grid-columns {
        .col-xs-#{$i} {
            @include grid-item($i);
        }
    }
}

.table-footer-content{
  display:flex;
  align-items: center;
  height:calc(49px - 0.5em);
}