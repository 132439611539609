@import "../_constants.scss";

.sectionDescription {
  margin-bottom: 2rem;
}

.headingSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 10px 15px;

  .title {
    font-size: $textSize-title;
    font-weight: bold;
  }

  .linkButton {
  }
}

.MainSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  border-bottom: 2px solid #eee;
  font-size: $textSize-medium;
  // padding-bottom: 30px;
  padding: 0 15px 30px 15px;
  margin-bottom: 30px;
}
