@import "../constants.scss";

.formGroup {
  > div {
    padding-bottom: 2em;
  }
}

.sum__input{
  font-weight: bold;;
}

.inputMoreText {
  margin-top: -2em;
}

.addFilesWrap {
  margin-bottom: 3em;

  .filesBlock {
    margin-top: 1em;
    margin-bottom: 1em;

    display: flex;
    row-gap: 0.3em;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;

    .fileItem {
      display: flex;
      column-gap: 0.6em;
      flex-direction: row;
      align-items: center;
    }

    .fileItemCat {
      font-size: 0.8em;
      font-style: italic;

      &:before {
        content: "(";
      }
      &:after {
        content: ")";
      }
    }
  }

  .fileUploadBlock {
    margin-bottom: 1em;
    display: flex;
    column-gap: 1em;
    flex-direction: row;
    align-items: center;

    @include screen("isMobile") {
      flex-direction: column;
    }

    .fileCatSelect {
      @include screen("isMobile") {
        margin-bottom: 10px;
      }
    }

    .fileField {
    }
  }
}
