@import "../_constants.scss";

.wrap {
  margin-top: 20px;

  .box {
    width: 100%;
    max-width: 400px;
    text-align: center;
    margin: 3em auto;

    // background-color: $secondary;
    padding: 1.5em 1em;
    @include block-shadow-1;

    &:hover {
      box-shadow: $box-shadow;
    }
  }

  .header {
    margin-bottom: 2em;
  }
}
