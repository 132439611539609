@import "../_constants.scss";

.wrap {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 10px;
}

.familyStoryDetail {
  margin-top: 20px;

  .familyStoryDetailHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2em;

    .metabox {
      flex-grow: 1;
      color: $text;

      .name {
        font-size: $textSize-title;
        font-weight: bold;
      }

      .patientName {
        font-size: $textSize-big;
      }

      .disease {
        font-style: italic;
      }
    }

    .navbox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;

      @include screen("isBig") {
        flex-direction: row;
      }

      .navigation {
        width: 60px;
        height: 40px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        a {
          color: $primary;

          &:hover {
            color: $primary;
            text-decoration: none;
          }
        }

        @include screen("isBig") {
          font-size: 3em;
        }

        .isDesktop {
          display: none;
          font-weight: 100;

          &:hover {
            font-weight: bold;
          }

          @include screen("isBig") {
            display: flex;
          }
        }

        .isMobile {
          display: flex;

          @include screen("isBig") {
            display: none;
          }
        }
      }
    }
  }

  .familyStoryDetailBody {
    .image {
      margin: 0 1em 2em 1em;

      @include screen("isBig") {
        max-width: 400px;
        float: right;
        margin: 1em 1em 2em 2em;
      }

      .main {
        width: 100%;
        height: auto;
        text-align: center;
        cursor: pointer;

        img {
          max-height: 350px;
        }
      }

      .others {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow: hidden;
        overflow-x: auto;

        @include custom-scroll-bar(5px, $primary);

        .imageWrap {
          $image-size: 60px;
          flex-shrink: 0;

          border: 1px solid $shadow-color;
          width: $image-size;
          height: $image-size;
          margin: 4px 5px;
          cursor: pointer;
          display: flex;
          justify-content: center;

          &:hover {
            border: 1px solid $primary;
          }

          a {
            margin: auto;
            img {
              max-width: $image-size;
              max-height: $image-size;
              width: auto;
              height: auto;
            }
          }
        }
      }
    }

    .lifeStory {
      text-align: justify;

      p {
        margin: 1em 0;
      }
    }
  }

  .familyStoryContacts {
    clear: both;
    padding: 1em;
    border-top: 1px solid $shadow-color;

    .contactsWrap {
      display: flex;
      flex-direction: column;
      row-gap: 0.6em;

      .address {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 1em;
      }

      .phone {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 1em;
      }

      .email {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 1em;
      }
    }

    .contactsInfoAgree {
      margin: 2em auto;
    }
  }
}

.familyStory {
  $desktopMaxWidth: 48%;
  $avatarHSize: 150px;
  $avatarVSize: 10vw; // just desktop
  $ctaVSize: 40px;

  width: 100%;
  overflow: hidden;
  margin-bottom: 2em;
  text-decoration: none;
  color: $text;

  display: flex;
  flex-direction: column;

  @include block-shadow-1-no-hover;

  @include screen("isBig") {
    flex-direction: row;
    max-width: $desktopMaxWidth;
  }

  .avatar {
    height: $avatarHSize;
    display: flex;
    flex-flow: row;
    align-items: center;
    overflow: hidden;

    @include screen("isBig") {
      width: $avatarVSize;
    }

    img {
      display: block;
      width: auto;
      height: auto;
      margin: auto;
      padding: 0.6em;

      max-height: $avatarHSize;

      @include screen("isBig") {
        max-width: $avatarVSize;
      }
    }
  }

  .textWrap {
    padding: 0.5em 0.8em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include screen("isBig") {
      padding: 0.7em 1.3em;
      width: calc(100% - #{$avatarVSize} - #{$ctaVSize});
    }

    .name {
      font-size: 1.3em;
      @include single-line;
    }

    .patientName {
      font-size: 1em;
      @include single-line;
    }

    .disease {
      margin-top: 10px;
      font-size: 0.95em;
      @include single-line;
    }
  }

  .cta {
    border: 1px blue;

    display: none;
    flex-direction: column;
    justify-content: center;

    width: $ctaVSize;
    height: 100%;
    color: $primary;
    text-align: center;
    padding: 10px;
    font-size: 2em;

    @include screen("isBig") {
      display: flex;
    }

    .ctaIcon {
      font-weight: 100;
    }
  }

  &:hover {
    text-decoration: none;

    .ctaIcon {
      font-weight: bold;
    }
  }
}
