@import "../constants.scss";

.drawerBackdrop {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display:flex;
    justify-content: center;
    align-items: center;

    .loading {
        background: rgba(0, 0, 0, 0.65);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .drawerContainer {
        width: 100%;
        min-width: 300px;
        max-width: 650px;
        height:400px;
        background-color: $white;
        border-radius:20px;
        box-shadow: $shadowColor;
        display:flex;
        align-items: center;
        padding:20px;
        gap:20px;
        position: relative;
        
        .close{
            position: absolute;
            top:10px;
            right:10px;
        }

        .dndInput{
            width:50%;
            height:100%;
            border:3px dashed $secondary;
            border-radius:20px;
            height:100%;
            padding:10px;
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            input[type="file"]{
                display: none;
            }

            .uploadButtons {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;           
            }

            .allowedTypes {
                font-size: $textSize-small;
                color: rgb(148, 148, 148);
                margin-top: 20px;
            }

        }

        .files{
            height:100%;
            width:50%;
            

            .filesHeader{
                font-weight: 600;
                font-size:$textSize-big;
            }

            .filesContainer{
                margin-top:9px;
                max-height: 320px;
                height:100%;
                overflow-y: auto;
                display:flex;
                flex-direction: column;
                gap:10px;
                padding-left:8px;
                padding-right:8px;
                justify-content: space-between;

                .uploadedFile {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 1;

                    li {
                        padding: 5px 0;
                    }
                }

                .sendButton {
                    margin-top: auto;
                }
            }
        }
    }
}

