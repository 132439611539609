@import "../_constants.scss";

.wrap {
  margin: 0 auto;

  // background-color: $shadow-color;
  padding: 1.5em 1em;
  text-align: center;

  .successIcon {
    text-align: center;
    font-size: 3em;

    i {
      --fa-animation-iteration-count: 1;
      --fa-animation-duration: 2s;
    }
  }

  .successText {
    text-align: center;
  }

  .note {
    margin-top: 2em;
    font-size: 0.8em;

    .title {
      font-weight: bold;
    }
  }
}
