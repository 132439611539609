@use "../_constants.scss" as *;

$color-nav: #fff;
$color-overlay: #222;

.wrap {
  transition: opacity 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  pointer-events: auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .overlay {
    background-color: rgba($color-overlay, 0.75);
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .container {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.25rem;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;
    width: 91.666667%;
    z-index: 50;
    width: auto;
    height: auto;

    .close,
    .next,
    .prev {
      cursor: pointer;
      position: absolute;
      color: $color-nav;
      z-index: 50;
      font-size: 3em;
    }

    .close {
      margin-top: 1rem;
      margin-right: 1rem;
      top: 0;
      right: 0;
      font-size: 1.6em;
    }

    .next {
      margin-right: 0.25rem;
      margin-left: 1rem;
      right: 0;
      bottom: 1.25rem;
      text-align: right;
      @media (min-width: 768px) {
        bottom: 50%;
      }
    }

    .prev {
      margin-left: 0.25rem;
      margin-right: 1rem;
      left: 0;
      bottom: 1.25rem;
      text-align: left;
      @media (min-width: 768px) {
        bottom: 50%;
      }
    }

    .content {
      padding: 1rem 1.5rem;
      text-align: center;

      img {
        max-width: 85vw;
        max-height: 85vh;
      }
    }
  }
}
