@import "../constants";

.backgroundDrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 90%;
    max-width: 650px;
    min-width: 270px;
    background-color: $white;
    border-radius: 10px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid $shadow-color;
      min-height: 42px;

      .title {
        font-size: $textSize-big;
        color: $text;
      }
    }

    .content {
      padding: 10px;
    }
  }
}
