@import "../_constants.scss";

.wrapper {
  .header {
    background-color: $secondary;
    padding: 2em 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;

    .logo {
      height: 100px;
    }
  }

  .content {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 1em 3em;
  }
}
